import { FC } from 'react';
import { FiStar } from 'react-icons/fi';

import { Wrapper } from './styles';

type TRating = {
  value?: number;
};

export const Rating: FC<TRating> = ({ value = 0 }) => {
  return (
    <Wrapper>
      <FiStar {...(value >= 1 && { className: 'filled' })} />
      <FiStar {...(value >= 2 && { className: 'filled' })} />
      <FiStar {...(value >= 3 && { className: 'filled' })} />
      <FiStar {...(value >= 4 && { className: 'filled' })} />
      <FiStar {...(value >= 5 && { className: 'filled' })} />
    </Wrapper>
  );
};
