import { FC } from 'react';

import { Rating } from './components/Rating';
import {
  Wrapper,
  Proportional,
  WrapperContent,
  Title,
  Price,
  WrapperPrice,
  WrapperImage,
} from './styles';

const CardSkeleton: FC = () => {
  return (
    <Wrapper>
      <Proportional>
        <WrapperImage />
      </Proportional>
      <Rating value={0} />
      <WrapperContent>
        <Title>Carregando Título</Title>
        <WrapperPrice>
          <Price>R$ 0,00</Price>
        </WrapperPrice>
      </WrapperContent>
    </Wrapper>
  );
};

export default CardSkeleton;
