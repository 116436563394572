import styled from 'styled-components';

export const WrapperAction = styled.div`
  display: flex;
  flex-direction: column;

  opacity: 0;

  transition: opacity 0.3s;
  will-change: opacity;

  margin-top: auto;

  @media (max-width: 768px) {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  text-decoration: none;
  width: 100%;
  background-color: ${(p) => p.theme.colors.background};

  cursor: pointer;
  display: flex;
  flex-direction: column;

  padding: 10px;
  gap: 10px;

  border-radius: ${(p) => p.theme.borderRadius};

  text-align: center;

  transition: box-shadow 0.3s, transform 0.3s;
  will-change: box-shadow, transform;

  &:hover {
    transform: translateY(-${(p) => p.theme.spacing.small});
    box-shadow: 0 3px 11.83px 1.17px rgb(0 0 0 / 15%);

    ${WrapperAction} {
      opacity: 1;
    }
  }
`;

export const Proportional = styled.div`
  position: relative;
  padding-bottom: 100%;
`;

export const WrapperImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-radius: ${(p) => p.theme.borderRadius};

  background-color: ${(p) => p.theme.colors.loadingHover};

  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );

  animation: 1s ease-in-out 0s infinite normal none running skeleton;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.small};
`;

export const Title = styled.div`
  font-size: ${(p) => p.theme.font.small};
  font-weight: 300;

  margin: 0 auto;

  user-select: none;
  border-radius: ${(p) => p.theme.borderRadius};

  background-color: ${(p) => p.theme.colors.loadingHover};
  color: transparent;

  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );

  animation: 1s ease-in-out 0s infinite normal none running skeleton;
`;

export const Price = styled.div`
  font-size: ${(p) => p.theme.font.medium};
  font-weight: bold;

  margin: 0 auto;

  user-select: none;
  border-radius: ${(p) => p.theme.borderRadius};

  background-color: ${(p) => p.theme.colors.loadingHover};
  color: transparent;

  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );

  animation: 1s ease-in-out 0s infinite normal none running skeleton;
`;

export const Flag = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.medium};

  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.textLight};

  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.small};
  border-radius: ${(p) => p.theme.borderRadius};
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const WrapperPrice = styled.div`
  gap: ${(p) => p.theme.spacing.small};
  display: flex;
  flex-direction: column;
`;
