import styled from 'styled-components';

interface IWrapperProps {
  initialColumnItemsCount: number;
}

export const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: max-content;
  grid-template-columns: ${({ initialColumnItemsCount }) =>
    `repeat(${initialColumnItemsCount}, 1fr)`};
  grid-gap: ${(p) => p.theme.spacing.xLarge};

  @media (max-width: 768px) {
    grid-gap: ${(p) => p.theme.spacing.large};
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 568px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
