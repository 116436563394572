/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

import { IProductsList } from '@pra-vendas-themes/interfaces/IProductsList';
import CardSkeleton from '@pra-vendas-themes/default/components/LoadingSkeleton/CardSkeleton';
import Card from '@pra-vendas-themes/default/components/Card';

import { Wrapper } from './styles';

const ProductsList: FC<IProductsList> = ({
  products,
  onClickProduct,
  onClickAddCart,
  isLoading,
  shimmerEffectQuantity = 3,
  initialColumnItemsCount = 4,
  quantityInBag,
  informQuantityAtProductCard,
  lastProductRef,
}) => {
  return (
    <Wrapper initialColumnItemsCount={initialColumnItemsCount}>
      {isLoading &&
        [...Array(shimmerEffectQuantity)].map((_, index) => (
          <CardSkeleton key={String(index)} />
        ))}

      {!isLoading &&
        products?.map((product, index) => {
          if (products.length === index + 1) {
            return (
              <div ref={lastProductRef}>
                <Card
                  key={product._id}
                  product={product}
                  quantityInBag={quantityInBag}
                  onClickProduct={() => onClickProduct?.(product)}
                  onClickAddCart={(quantity) =>
                    onClickAddCart?.(product, quantity)
                  }
                  informQuantityAtProductCard={informQuantityAtProductCard}
                />
              </div>
            );
          }
          return (
            <Card
              key={product._id}
              product={product}
              quantityInBag={quantityInBag}
              onClickProduct={() => onClickProduct?.(product)}
              onClickAddCart={(quantity) => onClickAddCart?.(product, quantity)}
              informQuantityAtProductCard={informQuantityAtProductCard}
            />
          );
        })}
    </Wrapper>
  );
};

export default ProductsList;
