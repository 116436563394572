import styled from 'styled-components';

export const WrapperAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  opacity: 0;

  transition: opacity 0.3s;
  will-change: opacity;

  margin-top: auto;

  > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  text-decoration: none;
  width: 100%;
  background-color: ${(p) => p.theme.colors.background};

  cursor: pointer;
  display: flex;
  flex-direction: column;

  padding: 10px;
  gap: 10px;

  border-radius: ${(p) => p.theme.borderRadius};

  text-align: center;

  transition: box-shadow 0.3s, transform 0.3s;
  will-change: box-shadow, transform;

  &:hover {
    transform: translateY(-${(p) => p.theme.spacing.small});
    box-shadow: 0 3px 11.83px 1.17px rgb(0 0 0 / 15%);

    ${WrapperAction} {
      opacity: 1;
    }
  }
`;

export const Proportional = styled.div`
  position: relative;
  padding-bottom: 100%;
`;

export const WrapperNoImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: ${(p) => p.theme.colors.backgroundHover};
  color: ${(p) => p.theme.colors.primary};

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 25%;
    height: 25%;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.small};
`;

export const Title = styled.div`
  font-size: ${(p) => p.theme.font.small};
  font-weight: 300;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const Price = styled.div`
  font-size: ${(p) => p.theme.font.medium};
  color: ${(p) => p.theme.colors.textSecondary};
  font-weight: bold;

  &.isDashed {
    opacity: 0.65;
    font-size: ${(p) => p.theme.font.small};
    font-weight: 500;
    text-decoration: line-through;
  }
`;

export const Flag = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.medium};

  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.textLight};

  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.small};
  border-radius: ${(p) => p.theme.borderRadius};
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const WrapperPrice = styled.div`
  gap: ${(p) => p.theme.spacing.small};
  display: flex;
  flex-direction: column;
`;

export const WrapperQuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${(p) => p.theme.borderRadius};
  border: 1px solid ${(p) => p.theme.colors.border};

  width: max-content;

  > input {
    height: 36px;
    min-width: 28px;
    max-width: 28px;
    color: ${(p) => p.theme.colors.primary};

    border: none;
    text-align: center;
    background: none;

    display: flex;
    align-items: center;
    justify-content: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    & > span {
      font-size: ${(p) => p.theme.font.medium};
    }
  }
`;

export const ButtonAction = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;

  height: 100%;
  min-width: 28px;
  max-width: 28px;
  color: ${(p) => p.theme.colors.primary};

  transition: opacity ease-in 0.3s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.5;
  }
`;
