/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  MouseEvent,
  FC,
  useState,
  ChangeEventHandler,
  useEffect,
  useMemo,
} from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { IoImageOutline } from 'react-icons/io5';

import Button from '@pra-vendas-themes/default/components/Button';
import { ICard } from '@pra-vendas-themes/interfaces/ICard';

import { useSiteSettings } from 'hooks/useSiteSettings';

import { formatCurrency } from 'utils/formatCurrency';

import { Rating } from './components/Rating';
import {
  Wrapper,
  Proportional,
  WrapperContent,
  WrapperAction,
  WrapperNoImage,
  Image,
  Title,
  Price,
  Flag,
  Label,
  WrapperPrice,
  WrapperQuantity,
  ButtonAction,
} from './styles';

const Card: FC<ICard> = ({
  product,
  onClickProduct,
  onClickAddCart,
  quantityInBag,
  informQuantityAtProductCard,
}) => {
  const { storeSettings } = useSiteSettings();
  const [productInStock, setProductInStock] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const { variations } = product || {};
  const hasManyVariations = (variations?.length || 0) >= 1;

  let buttonTitle = '';
  let values;

  if (hasManyVariations) {
    const cheaperVariation = variations?.sort((currentVari, nextVari) => {
      if (
        currentVari.promotionalPrice &&
        nextVari.promotionalPrice &&
        currentVari.promotionalPrice < nextVari.promotionalPrice
      )
        return -1;

      if (
        currentVari.promotionalPrice &&
        !nextVari.promotionalPrice &&
        currentVari.promotionalPrice < nextVari.price
      )
        return -1;

      if (
        nextVari.promotionalPrice &&
        !currentVari.promotionalPrice &&
        currentVari.price < nextVari.promotionalPrice
      )
        return -1;

      if (currentVari.price < nextVari.price) return -1;
      if (currentVari.price > nextVari.price) return 1;

      return 0;
    })?.[0];

    values = {
      name: product?.name,
      price: cheaperVariation?.price,
      promotionalPrice: cheaperVariation?.promotionalPrice,
      pictures: variations[0]?.pictures,
    };

    buttonTitle = 'VER VARIAÇÕES';
  } else {
    values = product;
    buttonTitle =
      storeSettings?.blockOutOfStockProducts &&
      (!values.stock || productInStock <= 0)
        ? 'ESGOTADO'
        : 'COMPRAR';
  }

  const { name, price, discount, promotionalPrice, pictures, stock } =
    values || {};

  useEffect(() => {
    if (hasManyVariations && !quantityInBag) return;

    setProductInStock((stock || 0) - quantityInBag!(product));
  }, [stock, quantityInBag, product, hasManyVariations]);

  const addOneOnQuantity = (
    number: number,
    event: MouseEvent<HTMLButtonElement>
  ): void => {
    if (stock === 0) return;
    event.stopPropagation();
    setQuantity((prev) => prev + number);
  };

  const handleChangeQuantity: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (stock === 0) return;

    const newQuantity = Number(event.target.value);

    if (!newQuantity) {
      setQuantity(0);
      return;
    }

    if (productInStock <= quantity) {
      setQuantity(productInStock);
      return;
    }

    setQuantity(newQuantity);
  };

  const onClickButton = (e: MouseEvent<HTMLButtonElement>): void => {
    if (buttonTitle === 'COMPRAR') {
      e.stopPropagation();
      onClickAddCart?.(quantity);
    }
  };

  const isOutOfStock = useMemo(
    () =>
      hasManyVariations &&
      storeSettings?.blockOutOfStockProducts &&
      productInStock <= 0,
    [hasManyVariations, productInStock, storeSettings?.blockOutOfStockProducts]
  );

  if (!product) return null;

  return (
    <Wrapper onClick={onClickProduct}>
      <Proportional>
        {pictures?.[0] ? (
          <Image src={pictures[0]} />
        ) : (
          <WrapperNoImage>
            <IoImageOutline />
          </WrapperNoImage>
        )}
        {discount && <Flag>{discount}</Flag>}
      </Proportional>
      <Rating value={product.ratingAverage} />
      <WrapperContent>
        <Title>{name}</Title>
        <WrapperPrice>
          {hasManyVariations ? (
            <>
              <Label>A partir de</Label>
              <Price>
                {formatCurrency(
                  ((promotionalPrice || 0) > 0 && promotionalPrice !== price
                    ? promotionalPrice
                    : price) || 0
                )}
              </Price>
            </>
          ) : (
            <>
              <Price
                className={
                  (promotionalPrice || 0) > 0 && promotionalPrice !== price
                    ? 'isDashed'
                    : ''
                }
              >
                {formatCurrency(price || 0)}
              </Price>

              {(promotionalPrice || 0) > 0 && promotionalPrice !== price && (
                <Price>{formatCurrency(promotionalPrice || 0)}</Price>
              )}
            </>
          )}
        </WrapperPrice>
      </WrapperContent>

      <WrapperAction>
        {!hasManyVariations &&
          buttonTitle === 'COMPRAR' &&
          informQuantityAtProductCard && (
            <WrapperQuantity onClick={(ev) => ev.stopPropagation()}>
              <ButtonAction
                onClick={(ev) => addOneOnQuantity(-1, ev)}
                disabled={isOutOfStock || quantity <= 1}
              >
                <FiMinus />
              </ButtonAction>

              <input
                type="number"
                value={quantity}
                disabled={isOutOfStock}
                onClick={(ev) => ev.stopPropagation()}
                onChange={handleChangeQuantity}
              />

              <ButtonAction
                onClick={(ev) => addOneOnQuantity(1, ev)}
                disabled={
                  hasManyVariations && storeSettings?.blockOutOfStockProducts
                    ? stock === 0 || productInStock <= quantity
                    : false
                }
              >
                <FiPlus />
              </ButtonAction>
            </WrapperQuantity>
          )}

        <Button onClick={onClickButton} disabled={isOutOfStock} font="small">
          {buttonTitle}
        </Button>
      </WrapperAction>
    </Wrapper>
  );
};

export default Card;
