import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: ${(p) => p.theme.spacing.nano};
  font-size: ${(p) => p.theme.font.medium};
  align-items: center;
  justify-content: center;

  color: ${(p) => p.theme.colors.primary};

  & svg {
    stroke-width: 1.2px;
    fill: transparent;

    &.filled {
      fill: #ff0;
    }
  }
`;
